import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';
import * as moment from 'moment';

import { environment } from './environments/environment';
import { extendConfiguration } from './config';
import 'moment/locale/de-ch';
import 'hammerjs';

extendConfiguration().then(async () => {
  if (environment.production) {
    enableProdMode();
  }

  if (environment.sentry) {
    Sentry.init({
      dsn: environment.sentry,
      environment: environment.apiUrl.split('.').splice(1, 3).join('.'),
      release:
        environment.version === '0.0.0' ? environment.commitHash : environment.version,
    });
  }

  const module = await import('./app/app.module');
  platformBrowserDynamic()
    .bootstrapModule(module.AppModule)
    .catch((err) => console.error(err));
});

moment.updateLocale('de', {
  week: {
    dow: 1, // Monday is the first day of the week.
  },
});
